<template>
  <div class="course-list">
    <div class="grid__row">
      <div class="grid__column grid__column--12">
        <HeaderContent type="primary" direction="row">
          <template #primary-left>
            <h2 class="course-list__heading heading--h3">Featured Venues</h2>
          </template>
          <template #primary-right>
            <router-link :to="coursesRouteNames.Courses">
              <USButton type="inline" label="View All Courses" />
            </router-link>
            <br />
            <router-link :to="clubsRouteNames.IndoorGolf">
              <USButton type="inline" label="View All Indoor Golf" />
            </router-link>
          </template>
        </HeaderContent>
      </div>
    </div>
    <CardList :cards="cards" />
  </div>
</template>

<script lang="ts" setup>
import { ClubSortField, SortOrder } from '__generated__/globalTypes'
import { listClubs, listClubs_listClubs_ListClubsOutput_clubs_courses } from '__generated__/listClubs' 

import { defineComponent, ref } from 'vue'

import { Card, CardType } from '@base/_types/Card'

import { useClient } from '@base/api/useClient'

import { ugcImageSrc } from '@base/lib/ugcImageSrc'

import CardList from '@base/components/CardList/CardList.vue'
import HeaderContent from '@base/components/HeaderContent/HeaderContent.vue'
import USButton from '@base/components/USButton/USButton.vue'

import { createDistanceContent } from '@base/lib/createDistanceContent'
import { createSlug } from '@base/lib/createSlug'

import { formatCoursePriceRange } from '@base/helpers/formatCoursePriceRange'

import { coursesRouteNames } from '@player/courses/router/routeNames'
import { LIST_CLUBS_QUERY } from '@base/api/queries/listClubs'
import { clubsRouteNames } from '@player/clubs/router/routeNames'

import imageEventPlaceholder from '@player/_assets/event-placeholder.jpg'

defineComponent({
  name: 'CourseList',
  components: {
    CardList,
    HeaderContent,
    USButton
  }
})

const client = useClient()
const lat = ref<number | null>()
const lon = ref<number | null>()
const cards = ref<any[]>([])


const generateClubLogo = (logo: string, clubName: string) => {
  return {
    src: logo ? ugcImageSrc(logo)[0] : '',
    srcset: logo ? ugcImageSrc(logo)[0] : '',
    alt: `The logo for ${clubName}`
  }
}

const fetchRandomClubs = async () => {
  try {
    
    const { data } = await client.query<listClubs>({
      query: LIST_CLUBS_QUERY,
      variables: {
        filter: {},
        sortField: ClubSortField.NAME,
        sortOrder: SortOrder.ASC,
        limit: 3,
        offset: 0,
        randomize: true
      },
      fetchPolicy: 'cache-first'
    })

    if (!data || data.listClubs.__typename !== 'ListClubsOutput') {
      return
    }

    // Populate our cards using the data returned
    // Loop through the 3 random clubs returned from the DB
    for (let i = 0; i < data.listClubs.clubs.length; i++) {

      const club = data.listClubs.clubs[i];

      const card = {
        type: CardType.CardListingCourse,
        data: {
          course: {},
          club: {}
        }
      }

      // Populate the club details
      card.data.club = {
        name: club.clubName,
        rating: club.averageRating,
        logo: generateClubLogo(club.logo || '', club.clubName),
        to: {
          name: clubsRouteNames.ClubSingle,
          params: {
            slug: createSlug(
              club.clubName,
              club.slug
            )
          }
        }
      }

      if (data.listClubs.clubs[i].courses.__typename !== 'ListCoursesOutput') { return; }
      const coursesWrapper = data.listClubs.clubs[i].courses as listClubs_listClubs_ListClubsOutput_clubs_courses;
      if (coursesWrapper.__typename !== 'ListCoursesOutput') { return; }
      const courses = coursesWrapper.courses;

      if (courses.length === 0) {
        // If this club doesn't have any courses (possibly an indoor golf venue), let's populate
        // the course details using the club details
        card.data.course = {
          name: club.clubName,
          location: club.city,
          distance: club.addressGeom && lat.value && lon.value ? createDistanceContent(club.addressGeom, {lat: lat.value, lon: lon.value}) : '',
          holes: 0,
          par: 0,
          price: '',
          featuredImage: { src: club.gallery && club.gallery[0] ? ugcImageSrc(club.gallery[0] || '')[0] : imageEventPlaceholder },
          to: {
            teeTimes: {
              name: clubsRouteNames.ClubSingle, 
              params: {
                slug: createSlug(club.clubName, club.slug)
              } 
            }
          }
        }
      } else {

        // If the club has 1 course, we can use that. If it has multiple, we'll select 1 at random
        let course;
        if (courses.length === 1) { course = courses[0]; }
        else {
          const randomIndex = Math.floor(Math.random() * courses.length);
          course = courses[randomIndex];
        }

        card.data.course = {
          name: course.courseName,
          location: club.city,
          distance: club.addressGeom && lat.value && lon.value ? createDistanceContent(club.addressGeom, {lat: lat.value, lon: lon.value}) : '',
          holes: course.numHoles,
          par: course.par,
          price: formatCoursePriceRange(course.priceRange || ''),
          featuredImage: { src: course.gallery && course.gallery[0] ? ugcImageSrc(course.gallery[0] || '')[0] : imageEventPlaceholder },
          to: {
            teeTimes: {
              name: coursesRouteNames.CourseSingle, 
              params: {
                slug: createSlug(course.courseName, course.slug)
              } 
            }
          }
        }
      }

      cards.value.push(card);
    }

  } catch (e) {
    console.error(e)
  }
}

await fetchRandomClubs();
</script>

<style lang="scss" scoped>
.course-list {
  margin-bottom: var(--spacing--template);
  margin-top: var(--spacing--template);
}
</style>
