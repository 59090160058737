<template>
  <div v-if="resourceCards && resourceCards.length" class="community-grid">
    <div class="grid__row">
      <div class="grid__column grid__column--12">
        <HeaderContent type="primary" direction="row">
          <template #primary-left>
            <h2 class="community-grid__heading heading--h3">
              Community Matters
            </h2>
          </template>
          <template #primary-right>
            <router-link
              :to="{
                name: communityRouteNames.CommunityBoard
              }"
            >
              <USButton type="inline" label="View More Posts" />
            </router-link>
          </template>
        </HeaderContent>
      </div>
    </div>
    <CardGridResource :cards="resourceCards" />
  </div>
</template>

<script lang="ts" setup>
import { ResourceSortField, SortOrder } from '__generated__/globalTypes'
import {
  listResources,
  listResources_listResources_ListResourcesOutput_resources
} from '__generated__/listResources'

import { defineComponent, ref } from 'vue'

import CardGridResource from '@base/components/CardGridResource/CardGridResource.vue'
import HeaderContent from '@base/components/HeaderContent/HeaderContent.vue'
import USButton from '@base/components/USButton/USButton.vue'

import { useClient } from '@base/api/useClient'
import { LIST_RESOURCES_QUERY } from '@base/api/queries/listResources'

import { Card, CardType } from '@base/_types/Card'
import { ugcImageSrc } from '@base/lib/ugcImageSrc'

import { communityRouteNames } from '@player/community/router/routeNames'

defineComponent({
  name: 'CommunityGrid',
  components: {
    CardGridResource,
    HeaderContent,
    USButton
  }
})

const client = useClient()

const resources = ref<
  listResources_listResources_ListResourcesOutput_resources[]
>([])
const resourceCards = ref<Card[]>([])

const formatCards = () => {
  resources.value.forEach((resource) => {
    let tags = resource.tags.map((tag) => tag.label)
    if (resource.video) {
      resourceCards.value.push({
        type: CardType.CardResourceVideo,
        data: {
          tags: tags,
          date: new Date(resource.date),
          heading: resource.body,
          video: {
            src: ugcImageSrc(resource.video)[0]
          },
          showMenu: false
        }
      })
    } else if (resource.galleryPaths && resource.galleryPaths.length) {
      resourceCards.value.push({
        type: CardType.CardResourceGallery,
        data: {
          tags: tags,
          date: new Date(resource.date),
          heading: resource.body,
          images: resource.galleryPaths?.map((galleryPath) => {
            return { src: ugcImageSrc(galleryPath)[0] }
          }),
          showMenu: false
        }
      })
    } else {
      resourceCards.value.push({
        type: CardType.CardResourceText,
        data: {
          tags: tags,
          date: new Date(resource.date),
          text: resource.body,
          showMenu: false
        }
      })
    }
  })
}

const fetchData = async () => {
  try {
    const { data } = await client.query<listResources>({
      query: LIST_RESOURCES_QUERY,
      variables: {
        sortOrder: SortOrder.DESC,
        sortField: ResourceSortField.DATE,
        limit: 6,
        offset: 0
      }
    })

    if (data.listResources.__typename !== 'ListResourcesOutput') {
      return
    }

    resources.value = data.listResources.resources
    await formatCards()
  } catch (e) {
    console.error(e)
    return
  }
}

await fetchData()
</script>

<style lang="scss" scoped>
.community-grid {
  margin-bottom: var(--spacing--template);
  margin-top: var(--spacing--template);
}
</style>
