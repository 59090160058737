<template>
  <div class="info-row">
    <BackgroundWrapper :background="infoRowBg" />
    <SplitContent>
      <template #left>
        <ImageWrapper v-bind="infoRowImageProps" class="info-row__image" />
      </template>
      <template #right>
        <div class="info-row__content">
          <h2 class="info-row__heading heading--h3">
            Golf experiences and destinations in one convenient place.
          </h2>
          <p class="info-row__body body--p1--regular">
            Discover, book, and enjoy the best golf courses and playing experiences.
          </p>
          <router-link :to="{ name: staticRouteNames.About }">
            <USButton class="margin-top--7" label="Learn More" />
          </router-link>
          <h2 class="info-row__sub-heading display--d1 margin-top--7">
            Find your fun.
          </h2>
        </div>
      </template>
    </SplitContent>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent } from 'vue'

import BackgroundWrapper from '@base/components/BackgroundWrapper/BackgroundWrapper.vue'
import ImageWrapper from '@base/components/ImageWrapper/ImageWrapper.vue'
import USButton from '@base/components/USButton/USButton.vue'
import SplitContent from '@player/_components/SplitContent/SplitContent.vue'
import { staticRouteNames } from '@player/static/router/routeNames'

import { Background } from '@base/_types/Background'

import imageHomeInfoRowImage from '@player/static/assets/home-info-row.png'
import imageHomeInfoRowBg from '@player/static/assets/home-info-row-bg.svg'

defineComponent({
  name: 'InfoRow',
  components: { BackgroundWrapper, ImageWrapper, SplitContent, USButton }
})

const infoRowImageProps = {
  alt: '',
  src: imageHomeInfoRowImage,
  aspectRatio: '1',
  fadeIn: true,
  lazy: true,
  objectFit: 'contain',
  objectPosition: 'center center'
}

const infoRowBg: Background = {
  fullWidth: true,
  overflow: true,
  media: [
    {
      type: 'image',
      position: {
        alignHorizontal: 'right_50',
        alignVertical: 'center',
        centerAnchor: false,
        cover: false,
        foreground: false,
        objectPositionHorizontal: 'center',
        objectPositionVertical: 'center'
      },
      imageSet: {
        mobile: {
          fadeIn: true,
          height: '800px',
          lazy: true,
          src: imageHomeInfoRowBg
        }
      }
    }
  ]
}
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint' as bp;

.info-row {
  margin-bottom: calc(var(--spacing--template) + var(--spacing--5));
  margin-top: calc(var(--spacing--template) + var(--spacing--3));

  &__image {
    margin-bottom: var(--spacing--6);
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;

    @include bp.min-width(bp.$breakpoint--7) {
      margin-bottom: 0;
    }
  }

  &__content {
    text-align: center;

    @include bp.min-width(bp.$breakpoint--7) {
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
      justify-content: center;
      text-align: left;
    }
  }

  &__heading {
    margin-bottom: var(--spacing--8);
  }

  &__sub-heading {
    color: var(--color--secondary-c3);
  }
}
</style>
