<template>
  <div class="club-logos">
    <div class="grid__row">
      <div class="grid__column grid__column--12">
        <h2 class="club-logos__heading heading--h3 text-align--center">
          Over 185 golf courses. Endless possibilities.
        </h2>
      </div>
      <div class="grid__column grid__column--12">
        <ul class="club-logos__logos">
          <li
            v-for="logo in logoImages"
            :key="logo.src"
            class="club-logos__logo"
          >
            <ImageWrapper v-bind="logo" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent } from 'vue'

import BackgroundWrapper from '@base/components/BackgroundWrapper/BackgroundWrapper.vue'
import ImageWrapper from '@base/components/ImageWrapper/ImageWrapper.vue'

import { Image } from '@base/_types/Image'

import logo1 from '@player/static/assets/upswing-golf-course-logo-1.png'
import logo2 from '@player/static/assets/upswing-golf-course-logo-2.png'
import logo3 from '@player/static/assets/upswing-golf-course-logo-3.png'
import logo4 from '@player/static/assets/upswing-golf-course-logo-4.png'
import logo5 from '@player/static/assets/upswing-golf-course-logo-5.png'
import logo6 from '@player/static/assets/upswing-golf-course-logo-6.png'
import logo7 from '@player/static/assets/upswing-golf-course-logo-7.png'

defineComponent({
  name: 'ClubLogos',
  components: { BackgroundWrapper, ImageWrapper }
})

const defaultLogoImageProps = {
  aspectRatio: '1',
  fadeIn: true,
  lazy: true
}

const logoImages: Image[] = [
  {
    alt: 'Jasper Park Lodge',
    src: logo1,
    ...defaultLogoImageProps
  },
  {
    alt: 'Highwood Golf',
    src: logo2,
    ...defaultLogoImageProps
  },
  {
    alt: '',
    src: logo3,
    ...defaultLogoImageProps
  },
  {
    alt: '',
    src: logo4,
    ...defaultLogoImageProps
  },
  {
    alt: '',
    src: logo5,
    ...defaultLogoImageProps
  },
  {
    alt: '',
    src: logo6,
    ...defaultLogoImageProps
  },
  {
    alt: '',
    src: logo7,
    ...defaultLogoImageProps
  }
]
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint' as bp;

.club-logos {
  margin-bottom: var(--spacing--template);
  margin-top: var(--spacing--template);

  &__heading {
    margin-top: var(--spacing--5);
  }

  &__logos {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: var(--spacing--6);
    width: 100%;

    @include bp.min-width(bp.$breakpoint--13) {
      flex-wrap: nowrap;
    }
  }

  &__logo {
    margin: var(--spacing--7) var(--spacing--6);
    width: 102px;

    @include bp.min-width(bp.$breakpoint--7) {
      width: 172px;
    }
  }
}
</style>
