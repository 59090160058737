<template>
  <div v-if="eventCards && eventCards.length" class="upcoming-events">
    <div class="grid__row">
      <div class="grid__column grid__column--12">
        <HeaderContent type="primary" direction="row">
          <template #primary-left>
            <h2 class="upcoming-events__heading heading--h3">
              Upcoming Events
            </h2>
          </template>
          <template #primary-right>
            <router-link :to="eventsRouteNames.Events">
              <USButton type="inline" label="View All" />
            </router-link>
          </template>
        </HeaderContent>
      </div>
    </div>
    <CardRow :cards="eventCards" />
  </div>
</template>

<script lang="ts" setup>
import { getEvents } from '__generated__/getEvents'
import { getEvent_getEvent_GetEventOutput_event } from '__generated__/getEvent'
import { EventSortField, SortOrder } from '__generated__/globalTypes'

import { defineComponent, ref } from 'vue'

import { Card, CardType } from '@base/_types/Card'

import { createSlug } from '@base/lib/createSlug'

import CardRow from '@base/components/CardRow/CardRow.vue'
import HeaderContent from '@base/components/HeaderContent/HeaderContent.vue'
import USButton from '@base/components/USButton/USButton.vue'

import { useClient } from '@base/api/useClient'
import { ugcImageSrc } from '@base/lib/ugcImageSrc'

import { LIST_EVENTS } from '@player/events/api/queries/listEvents'

import { eventsRouteNames } from '@player/events/router/routeNames'

import imageEventPlaceHolder from '@player/_assets/event-placeholder.jpg'

defineComponent({
  name: 'UpcomingEvents',
  components: {
    CardRow,
    HeaderContent,
    USButton
  }
})

const client = useClient()
const events = ref<getEvent_getEvent_GetEventOutput_event[]>([])
const eventCards = ref<Card[]>([])

const formatCards = () => {
  events.value.forEach((event) => {
    if (event.club.__typename === 'GetClubOutput') {
      eventCards.value.push({
        type: CardType.CardEvent,
        data: {
          eventName: event.name,
          clubName: event.club.club?.clubName || '',
          eventDate: new Date(event.dateStart),
          image: {
            src:
              event.gallery && event.gallery[0]
                ? ugcImageSrc(event.gallery[0] || '')[0]
                : imageEventPlaceHolder
          },
          exclusive: event.exclusive || false,
          to: {
            name: eventsRouteNames.EventSingle,
            params: {
              slug: createSlug(event.name, event.slug)
            }
          }
        }
      })
    }
  })
}

const fetchData = async () => {
  try {
    events.value = []

    const { data } = await client.query<getEvents>({
      query: LIST_EVENTS,
      variables: {
        filter: {
          dateStartAfter: new Date(new Date().setHours(0, 0, 0)),
          includeArchived: false
        },
        sort: {
          sortField: EventSortField.DATE_START,
          sortOrder: SortOrder.ASC
        },
        limit: 10
      },
      fetchPolicy: 'no-cache'
    })

    if (data.listEvents.__typename !== 'ListEventsOutput') {
      return
    }

    events.value = data.listEvents.events

    formatCards()
  } catch (e) {
    console.error(e)
  }
}

await fetchData()
</script>

<style lang="scss" scoped>
.upcoming-events {
  margin-bottom: var(--spacing--template);
}
</style>
