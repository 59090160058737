import { gql } from '@apollo/client/core'

export const GET_LEADERBOARD_ALL = gql`
  query getLeaderboardAll {
    getLeaderboard {
      ... on GetLeaderboardOutput {
        leaderboard {
          ... on LeaderBoardEntry {
            username
            points
            picture
            rank
          }
        }
      }
    }
  }
`
