export const props = {
  // clubId: {
  //   type: String,
  //   required: true
  // },
  // name: {
  //   type: String,
  //   default: () => undefined
  // },
  // sort: {
  //   type: String,
  //   default: () => undefined
  // },
  // page: {
  //   type: Number,
  //   default: () => undefined
  // }
}
