<template>
  <div class="membership-cta">
    <BackgroundWrapper :background="membershipCtaRowBg" />
    <SplitContent :flip="true">
      <template #left>
        <ImageWrapper
          v-bind="membershipCtaImageProps"
          class="membership-cta__image"
        />
      </template>
      <template #right>
        <div class="membership-cta__content">
          <h2 class="membership-cta__heading display--d1">
            Ways to <span>Find your fun.</span>
          </h2>
          <USList type="icon" class="membership-cta__perks body--p1--regular">
            <USIconListItem
              v-for="(perk, idx) in perks"
              :key="idx"
              :icon="perk.icon"
              color="var(--font-color--primary-b1)"
              css-diameter="var(--perks-icon-diameter)"
            >
              <a href="https://upswinggolf.ca/application-form.pdf">{{ perk.text }}</a>
            </USIconListItem>
          </USList>
          <p class="membership-cta__body body--p1--regular">
            Membership to UpSwing is complimentary and will direct you to finding a fun golf experience near you.
          </p>
          <div class="membership-cta__button-wrapper margin-top--7">
            <router-link :to="{ name: usersRouteNames.Register }">
              <USButton label="Join UpSwing" />
            </router-link>
          </div>
        </div>
      </template>
    </SplitContent>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent } from 'vue'

import BackgroundWrapper from '@base/components/BackgroundWrapper/BackgroundWrapper.vue'
import ImageWrapper from '@base/components/ImageWrapper/ImageWrapper.vue'
import SplitContent from '@player/_components/SplitContent/SplitContent.vue'
import USButton from '@base/components/USButton/USButton.vue'
import USIconListItem from '@base/components/USIconListItem/USIconListItem.vue'
import USList from '@base/components/USList/USList.vue'

import { accountRouteNames } from '@player/account/router/routeNames'
import { usersRouteNames } from '@player/users/router/routeNames'
import { Background } from '@base/_types/Background'

import imageHomeMembershipCtaRowImage from '@player/static/assets/home-membership-cta.png'
import imageHomeMembershipCtaRowBg from '@player/static/assets/home-membership-cta-bg.svg'

defineComponent({
  name: 'InfoRow',
  components: {
    BackgroundWrapper,
    ImageWrapper,
    SplitContent,
    USButton,
    USIconListItem,
    USList
  }
})

const membershipCtaImageProps = {
  alt: '',
  src: imageHomeMembershipCtaRowImage,
  aspectRatio: '1',
  fadeIn: true,
  lazy: true,
  objectFit: 'contain',
  objectPosition: 'center center'
}

const membershipCtaRowBg: Background = {
  fullWidth: true,
  overflow: true,
  media: [
    {
      type: 'image',
      position: {
        alignHorizontal: 'left_50',
        alignVertical: 'center',
        centerAnchor: false,
        cover: false,
        foreground: false,
        objectPositionHorizontal: 'center',
        objectPositionVertical: 'center'
      },
      imageSet: {
        mobile: {
          fadeIn: true,
          height: '800px',
          lazy: true,
          src: imageHomeMembershipCtaRowBg
        }
      }
    }
  ]
}

const perks = [
  {
    text: 'Events',
    icon: 'golf-club-bg'
  },
  {
    text: 'Activations',
    icon: 'target-bg'
  },
  {
    text: 'Grant Program',
    icon: 'piggybank-bg'
  },
]
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint' as bp;

$perks-icon-diameter-mobile: 30px;
$perks-icon-diameter-tablet: 38px;
$perks-icon-diameter-desktop: 44px;

.membership-cta {
  --perks-icon-diameter: #{$perks-icon-diameter-mobile};

  @include bp.min-width(bp.$breakpoint--tablet) {
    --perks-icon-diameter: #{$perks-icon-diameter-tablet};
  }

  @include bp.min-width(bp.$breakpoint--desktop) {
    --perks-icon-diameter: #{$perks-icon-diameter-desktop};
  }

  margin-bottom: calc(var(--spacing--template) + var(--spacing--5));
  margin-top: var(--spacing--template);

  &__image {
    margin-bottom: var(--spacing--6);
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;

    @include bp.min-width(bp.$breakpoint--7) {
      margin-bottom: 0;
    }
  }

  &__content {
    @include bp.min-width(bp.$breakpoint--7) {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      height: 100%;
    }
  }

  &__heading {
    color: var(--color--primary-b1);
    margin-bottom: var(--spacing--6);
    text-align: center;

    @include bp.min-width(bp.$breakpoint--tablet) {
      text-align: left;
    }

    span {
      color: var(--color--secondary-c3);
    }
  }

  &__body {
    text-align: center;

    @include bp.min-width(bp.$breakpoint--tablet) {
      text-align: left;
    }
  }

  &__perks {
    columns: 2;
    padding: 0 0 var(--spacing--6) 0 !important;
    font-size: var(--font-size--z5);
    font-weight: var(--font-weight--bold);
    color: var(--color--primary-b1);
  }

  &__button-wrapper {
    text-align: center;

    @include bp.min-width(bp.$breakpoint--tablet) {
      text-align: left;
    }
  }
}
</style>
