<template>
  <!-- <HeroBanner
    @submitCourseSearch="handleSearch"
  /> -->
  <HeroBannerEvents @submitEventSearch="handleEventSearch" />
  <UpcomingEvents />
  <UpcomingLeagues />
  <InfoRow />
  <ClubLogos />
  <CourseList />
  <MembershipCta />
  <LeaderboardRow />
  <CommunityGrid />
  <PreFooter />
</template>

<script lang="ts">
import { CourseSortField, EventSortField } from '__generated__/globalTypes'

import { defineComponent, toRefs } from 'vue'
import { useHead } from '@vueuse/head'
import { useRouter } from 'vue-router'
import { useUserStore } from '@base/store/userStore'

import ClubLogos from '@player/static/Home/components/ClubLogos.vue'
import CommunityGrid from '@player/static/Home/components/CommunityGrid.vue'
import CourseList from '@player/static/Home/components/CourseList.vue'
import HeroBanner from '@player/static/Home/components/HeroBanner.vue'
import HeroBannerEvents from '@player/static/Home/components/HeroBannerEvents.vue'
import InfoRow from '@player/static/Home/components/InfoRow.vue'
import LeaderboardRow from '@player/static/Home/components/LeaderboardRow.vue'
import MembershipCta from '@player/static/Home/components/MembershipCta.vue'
import PreFooter from '@player/static/Home/components/PreFooter.vue'
import UpcomingEvents from '@player/static/Home/components/UpcomingEvents.vue'
import UpcomingLeagues from '@player/static/Home/components/UpcomingLeagues.vue'

import { coursesRouteNames } from '@player/courses/router/routeNames'
import { eventsRouteNames } from '@player/events/router/routeNames'

import { playerTitle } from '@player/_lib/playerTitle'

// Club Logos
import logo1 from '@player/static/assets/upswing-golf-course-logo-1.png'
import logo2 from '@player/static/assets/upswing-golf-course-logo-2.png'
import logo3 from '@player/static/assets/upswing-golf-course-logo-3.png'
import logo4 from '@player/static/assets/upswing-golf-course-logo-4.png'
import logo5 from '@player/static/assets/upswing-golf-course-logo-5.png'
import logo6 from '@player/static/assets/upswing-golf-course-logo-6.png'
import heroBg from '@player/static/assets/header-home.jpg'
import soloPath from '@player/static/assets/solo-path.svg'
import soloBirdAndPath1 from '@player/static/assets/solo-bird-and-path-1.svg'
import soloBirdAndPath2 from '@player/static/assets/solo-bird-and-path-2.svg'
import soloBirdAndPath3 from '@player/static/assets/solo-bird-and-path-3.svg'
import familyLaughing from '@player/static/assets/family-laughing.png'
import familyLaughingBg from '@player/static/assets/family-laughing-bg.svg'
import exploreBg from '@player/static/assets/explore-bg.svg'
import flag1 from '@player/static/assets/flag-1.svg'
import flag2 from '@player/static/assets/flag-2.svg'
import flag3 from '@player/static/assets/flag-3.svg'
import hwGolf from '@player/static/assets/highwood-golf.png'
import phGolf from '@player/static/assets/ph-golf.png'
import bearGolf from '@player/static/assets/bear-golf.png'
import sundreGold from '@player/static/assets/sundre-golf.png'
import stcGolf from '@player/static/assets/stc-golf.png'
import jasperGolf from '@player/static/assets/jasper-golf.png'

export default defineComponent({
  name: 'Home',
  components: {
    ClubLogos,
    CommunityGrid,
    CourseList,
    HeroBannerEvents,
    InfoRow,
    LeaderboardRow,
    MembershipCta,
    PreFooter,
    UpcomingEvents,
    UpcomingLeagues
  },
  setup() {
    const apiKey = import.meta.env.VITE_GCP_GEOCODING_API_KEY
    const router = useRouter()

    useHead({
      title: playerTitle()
    })

    const userStore = toRefs(useUserStore())
    const { isAuth } = userStore

    const handleScrollToTop = () => {
      window.scrollTo(0, 0)
    }

    type CourseSearchEvent = {
      startDate: string | null
      endDate: string | null
      holes: number | undefined
      players: number | undefined
      lat: number | null
      lon: number | null
      loc: string | null
      course: string
      clubs: [string] | null
      amenitiesValue: [string] | null
    }

    type EventSearchEvent = {
      startDate: string | null
      endDate: string | null
      lat: number | null
      lon: number | null
      loc: string | null
      type: string[] | []
      clubs: string[] | []
      amenitiesValue: string[] | []
    }

    const handleEventSearch = (e: EventSearchEvent) => {
      const { startDate, endDate, lat, lon, loc, type, clubs, amenitiesValue } =
        e

      let fmtStart = startDate ? new Date(startDate) || undefined : undefined
      if (fmtStart) fmtStart.setHours(0, 0, 0)

      let fmtEnd = endDate ? new Date(endDate) || undefined : undefined
      if (fmtEnd) fmtEnd.setHours(23, 59, 59)

      const query = {
        start: fmtStart ? fmtStart.toISOString() : undefined,
        end: fmtEnd ? fmtEnd.toISOString() : undefined,
        lat: e.lat || undefined,
        lon: e.lon || undefined,
        loc: e.loc || undefined,
        types: e.type || undefined,
        clubs: e.clubs || undefined,
        amenities: e.amenitiesValue || undefined,
        sort: undefined as string | undefined
      }

      if (lat !== null || lon !== null || loc !== null)
        query.sort = EventSortField.DISTANCE
      router.push({ name: eventsRouteNames.Events, query })
    }

    const handleSearch = (e: CourseSearchEvent) => {
      const {
        startDate,
        endDate,
        holes,
        players,
        lat,
        lon,
        loc,
        course,
        clubs,
        amenitiesValue
      } = e
      const fmtStart = startDate ? new Date(startDate) || undefined : undefined
      if (fmtStart) fmtStart.setHours(0, 0, 0)

      let fmtEnd = endDate ? new Date(endDate) || undefined : undefined
      if (fmtEnd) fmtEnd.setHours(23, 59, 59)

      const query = {
        start: fmtStart ? fmtStart.toISOString() : undefined,
        end: fmtEnd ? fmtEnd.toISOString() : undefined,
        holes,
        players,
        lat: lat || undefined,
        lon: lon || undefined,
        loc: loc || undefined,
        query: course || undefined,
        clubs,
        amenities: amenitiesValue || undefined,
        sort: undefined as CourseSortField | undefined
      }
      if (lat !== null || lon !== null || loc !== null)
        query.sort = CourseSortField.DISTANCE
      router.push({ name: coursesRouteNames.Courses, query })
    }

    return {
      eventsRoute: eventsRouteNames.Events,
      heroBg,
      soloPath,
      soloBirdAndPath1,
      soloBirdAndPath2,
      soloBirdAndPath3,
      familyLaughing,
      familyLaughingBg,
      exploreBg,
      flag1,
      flag2,
      flag3,
      logo1,
      logo2,
      logo3,
      logo4,
      logo5,
      logo6,
      isAuth,
      handleScrollToTop,
      handleSearch,
      handleEventSearch,
      apiKey,
      hwGolf,
      phGolf,
      bearGolf,
      sundreGold,
      stcGolf,
      jasperGolf
    }
  }
})
</script>
