<template>
  <div v-if="members.length" class="leaderboard-row">
    <div class="grid__row">
      <div class="grid__column grid__column--12">
        <h2 class="leaderboard-row__heading heading--h3 text-align--center">
          Check out this month’s top members on our leaderboard.
        </h2>
        <div class="leaderboard-row__grid">
          <div
            v-for="member in members.slice(0, 3)"
            :key="member.username"
            class="leaderboard-row__user"
          >
            <ImageWrapper
              v-if="member.avatar.src"
              v-bind="member.avatar"
              class="leaderboard-row__avatar"
            />
            <div
              v-else
              class="leaderboard-row__avatar leaderboard-row__avatar-username"
            >
              <span class="leaderboard-row__avatar-label display--d2--medium">
                {{ member.username.charAt(0) }}
              </span>
            </div>
            <p
              class="leaderboard-row__username body--p1--medium text-align--center"
            >
              {{ member.username }}
            </p>
            <p
              class="leaderboard-row__points body--p1--regular text-align--center"
            >
              {{ Math.max(member.points, 0) }} pts
            </p>
          </div>
        </div>
        <div class="leaderboard-row__button-wrapper text-align--center">
          <router-link :to="leaderboardRouteNames.Leaderboard">
            <USButton label="View Leaderboard" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  getLeaderboardAll,
  getLeaderboardAll_getLeaderboard_GetLeaderboardOutput_leaderboard
} from '__generated__/getLeaderboardAll'

import { defineComponent, ref } from 'vue'

import ImageWrapper from '@base/components/ImageWrapper/ImageWrapper.vue'
import USButton from '@base/components/USButton/USButton.vue'
import USAvatar from '@base/components/USAvatar/USAvatar.vue'

import { useClient } from '@base/api/useClient'
import { ugcImageSrc } from '@base/lib/ugcImageSrc'
import { Image } from '@base/_types/Image'

import { GET_LEADERBOARD_ALL } from '@player/_api/queries/getLeaderboardAll'

import { leaderboardRouteNames } from '@player/leaderboard/router/routeNames'

type leaderboardMembers = {
  username: string
  avatar: Image
  points: number
}

defineComponent({
  name: 'LeaderboardRow',
  components: { ImageWrapper, USButton }
})

const client = useClient()
const members = ref<leaderboardMembers[]>([])

const avatarProps = {
  aspectRatio: '1',
  fadeIn: true,
  height: '140px',
  lazy: true,
  objectFit: 'cover',
  width: '140px'
}

const formatLeaderboard = (
  leaderboard: getLeaderboardAll_getLeaderboard_GetLeaderboardOutput_leaderboard[]
) => {
  leaderboard.forEach((member) => {
    members.value.push({
      username: member.username,
      avatar: {
        src: ugcImageSrc(member.picture || '')[0],
        alt: `Profile picture for ${member.username}`,
        ...avatarProps
      },
      points: member.points
    })
  })
}

const fetchData = async () => {
  try {
    const { data } = await client.query<getLeaderboardAll>({
      query: GET_LEADERBOARD_ALL,
      fetchPolicy: 'no-cache'
    })

    if (data.getLeaderboard.__typename !== 'GetLeaderboardOutput') {
      return
    }
    members.value = []

    formatLeaderboard(data.getLeaderboard.leaderboard)
  } catch (e) {
    console.error(e)
  }
}

await fetchData()
</script>

<style lang="scss" scoped>
.leaderboard-row {
  margin-bottom: var(--spacing--template);
  margin-top: var(--spacing--template);

  &__grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: var(--spacing--7);
  }

  &__user {
    margin: var(--spacing--5);
  }

  &__avatar {
    -webkit-mask-image: -webkit-radial-gradient(
      white,
      black
    ); // Safari border-radius fix
    border-radius: 50%;
    height: 140px;
    margin-bottom: var(--spacing--6);
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--spacing--6);
    overflow: hidden;
    width: 140px;
  }

  &__avatar-username {
    background-color: var(--color--primary-b1);
    color: var(--color--neutral-a5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
  }

  &__avatar-label {
    font-size: var(--font-size--z1);
  }

  &__username {
    font-weight: var(--font-weight--semibold);
    word-break: break-word;
  }

  &__points {
    color: var(--color--neutral-a2);
    margin-top: var(--spacing--9);
  }
}
</style>
