<template>
  <div class="pre-footer">
    <BackgroundWrapper :background="preFooterBg" />
    <div class="grid__row">
      <div class="grid__column grid__column--12">
        <h2 class="pre-footer__heading heading--h3 text-align--center">
          Find exciting golf events near you!
        </h2>
        <div class="pre-footer__link-wrapper text-align--center">
          <router-link :to="eventsRouteNames.Events" class="pre-footer__link">
            <USButton label="Explore Events" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent } from 'vue'

import BackgroundWrapper from '@base/components/BackgroundWrapper/BackgroundWrapper.vue'
import USButton from '@base/components/USButton/USButton.vue'

import { Background } from '@base/_types/Background'
import { eventsRouteNames } from '@player/events/router/routeNames'

import imageHomePreFooterBg from '@player/static/assets/home-pre-footer-bg.svg'

defineComponent({
  name: 'PreFooter',
  components: { BackgroundWrapper, USButton }
})

const preFooterBg: Background = {
  fullWidth: true,
  overflow: true,
  media: [
    {
      type: 'image',
      position: {
        alignHorizontal: 'right_75',
        alignVertical: 'center',
        centerAnchor: false,
        cover: false,
        foreground: false,
        objectPositionHorizontal: 'center',
        objectPositionVertical: 'center'
      },
      imageSet: {
        mobile: {
          fadeIn: true,
          lazy: true,
          src: imageHomePreFooterBg
        }
      }
    }
  ]
}
</script>

<style lang="scss" scoped>
.pre-footer {
  margin-bottom: var(--spacing--3);
  margin-top: var(--spacing--3);

  &__heading {
    margin-bottom: var(--spacing--6);
  }
}
</style>
